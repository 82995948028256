window.addEventListener("load", () => {
   document.querySelectorAll(".block-secteurs__trigger").forEach(trigger => {
      trigger.onclick = () => {
         if (trigger.getAttribute("aria-pressed") == "false") {
            // Update button active
            document.querySelector('.block-secteurs__trigger[aria-pressed="true"]').setAttribute("aria-pressed", "false");
            trigger.setAttribute("aria-pressed", "true");

            // Update active view
            document.querySelector(".block-secteurs__view.active").classList.remove("active");
            document.getElementById(trigger.dataset.relatedSecteur).classList.add("active");
         }
      };
   });
});
